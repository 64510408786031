<template>
    <div class="rounded border p-2 shadow-md text-center" :class="{ 'bg-red-200 border-red-300' : shop.fake, 'bg-green-200 border-green-300' : !shop.fake }">
        <p class="font-semibold whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
            <font-awesome-icon icon="times" class="text-red-500 mr-1" v-if="shop.fake" />
            <font-awesome-icon icon="check" class="text-green-500 mr-1" v-else />
            {{ shop.url }}
            <font-awesome-icon icon="times" class="text-red-500 mr-1" v-if="shop.fake" />
            <font-awesome-icon icon="check" class="text-green-500 mr-1" v-else />
        </p>
        <p class="text-xs">Requested <span class="font-bold">{{ shop.num_checked }}</span> times</p>
        <p class="text-gray-700 text-xs">
            Last crawled {{ formatDate(shop.last_crawled) }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'FakeShop',
    props: {
        shop: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatDate(date) {
            const d = new Date(date);
            return d.toLocaleDateString() + ' - ' + d.toLocaleTimeString();
        },
    }
}
</script>