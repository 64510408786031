import { createApp } from 'vue';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes, faQuestion, faCog, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import CookieConsent from 'vue-cookieconsent';
import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css';

library.add(faCheck, faTimes, faQuestion, faCog, faEyeSlash);

import './index.css';

const app = createApp(App).use(CookieConsent, {
    current_lang: 'en',
    force_consent: true,
    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'We use cookies to ensure the basic functionalities of our website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.'
                    },
                    {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
                        toggle: {
                            value: 'necessary',
                            enabled: true
                        }
                    },
                    {
                        title: 'More information',
                        description: 'For more details relative to cookies and other sensitive data, please read our full <a href="https://www.trustami.com/datenschutz/" class="cc-link">privacy policy</a>.'
                    }
                ]
            }
        }
    }
});

app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');