<template>
    <div :class="{ 'hidden' : !visible }">
        <div class="flex flex-wrap max-w-report" v-if="!hasAcceptedCookies">
            <p>
                You need to <a href="#" @click="showCookies" class="text-blue-600">accept all cookies</a> to be able to report a shop.
            </p>
        </div>
        <div class="flex flex-wrap max-w-report" v-else>
            <input type="url" class="px-3 py-2 border border-gray-400 outline-none w-full rounded"
                   v-model="form.shopUrl" id="report-shopUrl" placeholder="Shop URL"/>
            <input type="text" class="px-3 py-2 border border-gray-400 outline-none w-full rounded mt-1"
                   v-model="form.reason" id="report-reason" placeholder="Report reason"/>
            <div class="g-recaptcha mt-1" data-sitekey="6LdWiCYcAAAAADLw8MQIijWR4PMlV8LMJPUmXIQw"></div>
            <button
                class="px-3 py-2 border outline-none bg-success text-white border-green-600 hover:bg-green-600 transition w-full rounded mt-1"
                @click="reportShop">
                <span v-if="!isLoading">
                    Report website
                </span>
                <span v-else>
                    <font-awesome-icon icon="cog" class="animate-spin"/>
                    Reporting...
                </span>
            </button>
        </div>
        <div class="mt-4 pt-3 border-t border-gray-400 text-lg text-center" v-if="result">
            <p v-if="result === 'success'">
                <font-awesome-icon icon="check" class="text-green-500 mr-1"/>
                The shop has been reported.
            </p>
            <p v-else>
                <font-awesome-icon icon="times" class="text-red-500 mr-1"/>
                Failed to report the shop: {{ result }}
            </p>
        </div>
    </div>
</template>

<script>
window.onReCaptchaLoad = function () {
    window.grecaptcha.render(document.getElementsByClassName('g-recaptcha')[0]);
};

const axios = require('axios');

export default {
    name: 'Report',
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        hasAcceptedCookies: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {
                shopUrl: '',
                reason: '',
                recaptcha: ''
            },
            result: '',
            isLoading: false
        };
    },
    methods: {
        showCookies() {
            this.$cc.showSettings();
        },
        async reportShop() {
            this.isLoading = true;
            try {
                this.form.recaptcha = window.grecaptcha ? window.grecaptcha.getResponse() : '';

                const json = await axios.post('/report.php', this.form);

                if (json.data && json.data.status) {
                    this.result = 'success';

                    this.form.shopUrl = '';
                    this.form.reason = '';
                } else if (json.data) {
                    this.result = json.data.data;
                } else {
                    this.result = 'Unknown error';
                }
            } catch (e) {
                this.result = 'Unknown error';
            }

            window.grecaptcha.reset();
            this.isLoading = false;
        }
    }
}
</script>