<template>
    <Index :hasAcceptedCookies="hasAcceptedCookies"></Index>
</template>

<script>
import Index from './components/Index.vue';

export default {
    name: 'App',
    components: {
        Index
    },
    data() {
        return {
            hasAcceptedCookies: false
        };
    },
    mounted() {
        const updateAcceptedCookies = () => {
            this.hasAcceptedCookies = this.$cc.getUserPreferences().accepted_categories.includes("necessary");

            if (this.hasAcceptedCookies) {
                const recaptcha = document.createElement('script');

                recaptcha.src = 'https://www.google.com/recaptcha/api.js?onload=onReCaptchaLoad&render=explicit';

                document.head.appendChild(recaptcha);
            }
        };

        updateAcceptedCookies();

        window.addEventListener('consentChanged', () => {
            updateAcceptedCookies();
        });
    }
}
</script>
