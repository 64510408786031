<template>
    <div v-if="visible">
        <div class="flex mobile:flex-wrap">
            <input type="url" class="px-3 py-2 border border-gray-400 rounded-l outline-none w-examine-full mobile:w-full mobile:rounded" v-model="form.shopUrl" placeholder="Shop URL" @input="abortTimeout()" />
            <button class="px-3 py-2 border rounded-r outline-none bg-success text-white border-1 w-examine border-green-600 hover:bg-green-600 transition mobile:w-full mobile:rounded mobile:mt-1" @click="checkShop">
                <span v-if="!isLoading">
                    Examine website
                </span>
                <span v-else>
                    <font-awesome-icon icon="cog" class="animate-spin" />
                    Loading...
                </span>
            </button>
        </div>
        <div class="mt-4 pt-3 border-t border-gray-400 text-lg text-center" v-if="result">
            <p v-if="result === 'legit'">
                <font-awesome-icon icon="check" class="text-green-500 mr-1" />
                The shop has been detected to be legit.
            </p>
            <p v-else-if="result === 'fake'">
                <font-awesome-icon icon="times" class="text-red-500 mr-1" />
                The shop has been detected to be fake.
            </p>
            <p v-else-if="result === 'offline'">
                <font-awesome-icon icon="eye-slash" class="text-gray-500 mr-1" />
                The shop seems to be offline or the domain does not exist.
            </p>
            <p v-else-if="result === 'in_progress'">
                This may take a moment...
            </p>
            <p v-else>
                <font-awesome-icon icon="question" class="text-yellow-500 mr-1" />
                An error occurred while trying to detect the shop, please check your input.
            </p>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
const axios = require('axios');

export default {
    name: 'Main',
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            form: {
                shopUrl: ''
            },
            result: '',
            isLoading: false,

            timeout: null
        };
    },
    methods: {
        abortTimeout() {
            clearTimeout(this.timeout);
        },
        async checkShop(pIsRefresh) {
            clearTimeout(this.timeout);

            if (!this.form.shopUrl.trim()) {
                return;
            }

            this.isLoading = true;
            this.result = 'in_progress';

            try {
                // NOTE: The trailing / is required otherwise we run into problems with the API. (Maybe future todo?)
                const json = await axios.get('/api/detect/?url=' + encodeURIComponent(this.form.shopUrl) + '&max_age=86400' + (pIsRefresh ? '&is_polling=1' : ''));

                if (json && json.data && 'label' in json.data) {
                    this.result = this.formatResult(json.data.label);
                }
            } catch (e) {
                this.result = this.formatResult(null);
            }

            $('body').trigger('fakeshop.checked');
            this.isLoading = false;

            if (this.result === 'in_progress') {
                this.timeout = setTimeout(() => {
                    this.checkShop(true);
                }, 10000);
            }
        },
        formatResult(res) {
            res = res ? res.toLowerCase() : res;

            switch(res) {
                case 'fake':
                case 'legit':
                case 'offline':
                case 'in_progress':
                    return res;
                default:
                    return 'unknown';
            }
        }
    }
}
</script>
